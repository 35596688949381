import { createApp } from 'vue';
import App from './App.vue';
import { TUIComponents, TUICore } from './TUIKit';
// import TUICallKit
import ElementPlus from 'element-plus'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import 'element-plus/dist/index.css'
import { TUICallKit } from '@tencentcloud/call-uikit-vue';
import * as ElementPlusIconsVue from '@element-plus/icons-vue'

import axios from 'axios'
const SDKAppID = 1400806452; // Your SDKAppID
// const userID = 'lihenghong'; // User ID
const params = new URLSearchParams(window.location.search)
const shopId = params.get('shopId')
const specUid = params.get('specUid')
const uid = params.get('uid')
const userId = params.get('userId')
const plat = params.get('plat')
const type = params.get('type')
const goodsId = params.get('goodsid')

const app = createApp(App)

// init TUIKit
const TUIKit = TUICore.init({
  SDKAppID,
});
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
axios.get('https://www.ictrade.cn/im/index', {
// axios.get('https://www.ictrade.cn/im/index', {
  params: {
    userId,
    uid,
    type,
    plat,
    specUid,
    shopId,
    goodsId
  }
})
  .then(response => {
    TUIKit.login({
      userID: response.data.data.visiter,
      userSig: response.data.data.sig// The password with which the user logs in to IM. It is the ciphertext generated by encrypting information such as userID.For the detailed generation method, see Generating UserSig
    });
    
    localStorage.setItem("serverId",response.data.data.type+'-'+response.data.data.linkId);
    // localStorage.setItem("serverId", "GROUP@TGS#3OHUXSDNL");
    if(type==="service"){
    localStorage.setItem("serverId",'C2C-ictrade_admin');
    }
    localStorage.setItem("selfId", response.data.data.visiter);
    // const shopdata: any = JSON.parse(localStorage.getItem("shopIdData") || "{}") || {};
    // shopdata[response.data.data.linkId] = shopId||userId;
    // localStorage.setItem("shopIdData", JSON.stringify(shopdata))
    // TUIKit add TUIComponents
    TUIKit.use(TUIComponents);
    // TUIKit add TUICallKit
    TUIKit.use(TUICallKit);
    
    // login TUIKit
    for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
      app.component(key, component)
    }
    app.use(TUIKit).use(ElementPlus, {
      locale: zhCn,
    }).mount('#app');
  })
  .catch(error => {
    console.error(error)
  })

