import type { AxiosProgressEvent, GenericAbortSignal } from 'axios'
import { post,get } from '../utils/request'



// export function bindApiKey<T>(data:any) {
//   return post<T>({
//     url: '/bindApiKey',
//     data,
//   })
// }


export function getAddresslist<T>(data:any) {
  return post<T>({
    url: '/ImApi/addressList',
    data,
  })
}


export function getprovince<T>(data:any) {
  return post<T>({
    url: '/ImApi/getprovince',
    data,
  })
}


export function getCity<T>(data:any) {
  return post<T>({
    url: '/ImApi/getCity',
    data,
  })
}

export function getDistrict<T>(data:any) {
  return post<T>({
    url: '/ImApi/getDistrict',
    data,
  })
}


export function setOrderAddress<T>(data:any) {
  return post<T>({
    url: '/ImApi/setOrderAddress',
    data,
  })
}

export function saveAddress<T>(data:any) {
  return post<T>({
    url: '/ImApi/saveAddress',
    data,
  })
}



export function getShopInfo<T>(data:any) {
  return post<T>({
    url: '/im/getShop',
    data,
  })
}



export function getAllBrand<T>(data:any) {
  return post<T>({
    url: '/ImApi/brandList',
    data,
  })
}


export function getGoodList<T>(data:any) {
  return post<T>({
    url: '/ImApi/goodsList',
    data,
  })
}


export function getGoodInfo<T>(data:any) {
  return get<T>({
    url: '/goods/goodsinfo',
    data,
  })
}

export function getExpireList<T>(data:any) {
  return post<T>({
    url: '/ImApi/getTokenList',
    data,
  })
}


export function getHistoryMessage<T>(data:any) {
  return post<T>({
    url: '/ImApi/getChatList',
    data,
  })
}