
import {
  defineComponent,
  watchEffect,
  reactive,
  toRefs,
  ref,
  onMounted,
} from "vue";
import axios from "axios";
import {
  getAddresslist,
  getprovince,
  getCity,
  getDistrict,
  setOrderAddress,
  saveAddress,
} from "@/api";
import type { FormInstance, FormRules } from "element-plus";
import { ElMessage } from "element-plus";
export default defineComponent({
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    flow: {
      type: String,
      default: "",
    },
  },
  setup(props: any, ctx: any) {
    const data = reactive({
      data: {},
    });
    // onMounted(()=>{
    //   getprovinceList()
    // })
    let form = reactive({
      consigner: "",
      mobile: "",
      address: "",
      company: "",
      province: "",
      city: "",
      district: "",
      token: "",
      regionId: "",
    });

    const ruleFormRef = ref<FormInstance>();
    let provinces: any = ref([]);
    let citys: any = ref([]);
    let districts: any = ref([]);

    let orderNo = ref("");
    let token: any = ref("");
    const rules = reactive<FormRules<any>>({
      consigner: [
        {
          required: true,
          message: "请输入收货人姓名",
          trigger: "blur",
        },
      ],
      company: [
        {
          required: true,
          message: "请输入收货公司",
          trigger: "blur",
        },
      ],
      mobile: [
        {
          required: true,
          message: "请输入手机",
          trigger: "change",
        },
      ],
      address: [
        {
          required: true,
          message: "请输入地址",
          trigger: "change",
        },
      ],
    });

    const dialogTableVisible = ref<any>(false);
    const innerVisible = ref<any>(false);

    const addressList = ref<any>([]);
    const regionIds = ref<any>(0);
    watchEffect(() => {
      data.data = props.data;
    });
    const getGoodsOrderData = (data: any) => {
      return JSON.parse(data.split("saveGoodsOrder?*")[1]);
    };
    const getGoodsOrderData2 = (data: any) => {
      return JSON.parse(data.split("goodsOrder?*")[1]);
    };

    const getGoodsData = (data: any) => {
      return JSON.parse(data.split("card?*")[1]);
    };

    const getMsgData = (str: any, data: any, arg: any) => {
      var obj: any = JSON.parse(data.split(`${str}?*`)[1]);
      return obj[arg];
    };

    const submitForm = async (formEl: FormInstance | undefined) => {
      form.token = token;
      if (!form.consigner) {
        ElMessage({
          showClose: true,
          message: "请填写收货人姓名",
          type: "error",
        });
        return;
      }
      if (!form.mobile) {
        ElMessage({
          showClose: true,
          message: "请填写电话",
          type: "error",
        });
        return;
      }
      if (!form.address) {
        ElMessage({
          showClose: true,
          message: "请填写收货人地址",
          type: "error",
        });
        return;
      }
      if (!form.company && regionIds.value > 1) {
        ElMessage({
          showClose: true,
          message: "请填写收货公司",
          type: "error",
        });
        return;
      }

      if (
        (!form.province || !form.city || !form.district) &&
        regionIds.value < 2
      ) {
        ElMessage({
          showClose: true,
          message: "请填写地区信息",
          type: "error",
        });
        return;
      }
      saveAddress(form).then((res: any) => {
        if (res.code) {
          innerVisible.value = false;
          setOrderAddressFunc(res.data);
        }
      });
      // if (!formEl) return;
      // await formEl.validate((valid, fields) => {
      //   if (valid) {
      //     console.log("submit!");
      //   } else {
      //     console.log("error submit!", fields);
      //   }
      // });
    };

    const addCart = (bargainId: any) => {
      axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
      axios
        // .post("https://www.ictrade.cn/goods/saveCartFixed", {
        .post("https://www.ictrade.cn/goods/saveCartFixed", {
          bargainId,
        })
        .then((response) => {
          if (response.data.code > 0) {
            ElMessage({
              showClose: true,
              message: "添加成功",
              type: "success",
            });
          } else {
            ElMessage.error(response.data.message);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const selectAddress = () => {
      // dialogTableVisible.value= true
    };

    const changeProvince = () => {
      getCity({ province_id: form.province }).then((res: any) => {
        citys.value = res;
      });
    };
    const changeCity = () => {
      getDistrict({ city_id: form.city }).then((res: any) => {
        districts.value = res;
      });
    };

    const addAddress = () => {
      innerVisible.value = true;
      getprovinceList();
    };

    const comfirAddress = (orederNo: any) => {
      let comfirAddressList: any = localStorage.getItem("comfirAddress");
      if (comfirAddressList) {
        comfirAddressList = JSON.parse(comfirAddressList);
      } else {
        comfirAddressList = [];
      }
      comfirAddressList.push(orederNo);
      localStorage.setItem("comfirAddress", JSON.stringify(comfirAddressList));
      ElMessage({
        showClose: true,
        message: "确认成功",
        type: "success",
      });
    };

    const getCardarg = (data: any, arg: any) => {
      var obj: any = JSON.parse(data.split("card?*")[1]);
      return obj[arg];
    };

    const formattedDate = (times: any) => {
      const date = new Date(times * 1000); // 根据时间戳创建Date对象
      console.log(date, "date"); // Fri Jul 14 2023 16:29:44 GMT+0800 (中国标准时间) 'date'

      const year = date.getFullYear(); // 获取年份
      const month = date.getMonth() + 1; // 获取月份，需要加1
      const day = date.getDate(); // 获取日期
      const hour = date.getHours(); // 获取小时
      let minute: any = date.getMinutes(); // 获取分钟
      const second = date.getSeconds(); // 获取秒数
      if (minute < 10) {
        minute = "0" + minute;
      }
      return `${year}-${month}-${day} ${hour}:${minute}`;
    };

    const editAddress = (tokens: any, regionId: any, orderNos: any) => {
      orderNo = orderNos;
      token = tokens;
      dialogTableVisible.value = true;
      form.regionId = regionId;
      regionIds.value = regionId;
      getAddresslist({ token: tokens.toString(), regionId }).then(
        (res: any) => {
          addressList.value = res.data.data;

          // console.info(res)
          // return {
          //   list: res.data,
          // };
        }
      );
    };

    const jsonOrder = (order: any) => {
      let idList = order.split(",");
      return idList;
    };

    const getprovinceList = () => {
      getprovince({}).then((res: any) => {
        provinces.value = res;
        console.log(provinces, "xx");
      });
    };

    const setOrderAddressFunc = (addressId: any) => {
      setOrderAddress({ addressId, orderNo, token }).then((res: any) => {
        if (res.code > 0) {
          ElMessage({
            showClose: true,
            message: "修改成功",
            type: "success",
          });
          dialogTableVisible.value = false;
        } else {
          ElMessage({
            showClose: true,
            message: res.message,
            type: "error",
          });
        }
      });
    };

    const getComfirStatus = (orederNo: any) => {
      let comfirAddressList: any = localStorage.getItem("comfirAddress");
      if (comfirAddressList) {
        if (comfirAddressList.indexOf(orederNo) < 0) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    };

    const format = (num: any) => {
      let [int, fraction = ""]:any = (num + "").split(".");
      let ans = "";
      const len = int.length;
      int = int.split("").reverse();
      for (let i = 0; i < len; i++) {
        if (i !== 0 && i % 3 === 0) {
          ans = int[i] + "," + ans;
        } else {
          ans = int[i] + ans;
        }
      }

      if (fraction !== "") ans += "." + fraction;

      return ans;
    };

    return {
      ...toRefs(data),
      getCardarg,
      format,
      getGoodsOrderData,
      getGoodsOrderData2,
      addCart,
      form,
      getMsgData,
      editAddress,
      dialogTableVisible,
      addressList,
      selectAddress,
      provinces,
      changeProvince,
      changeCity,
      citys,
      districts,
      innerVisible,
      addAddress,
      rules,
      submitForm,
      comfirAddress,
      formattedDate,
      ruleFormRef,
      setOrderAddressFunc,
      regionIds,
      getComfirStatus,
      jsonOrder,
      getGoodsData,
    };
  },
});
